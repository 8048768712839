import { CartContext } from '../utils/CartContext';
import { UserContext } from '../utils/UserContext'; // Import UserContext
import { sendOrderDetailsToDB, prepareOrderDetails } from '../utils/MainFunctionality';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

const PaymentSuccessfulPage = () => {
    const { clearCart, cartItems } = useContext(CartContext);
    const { user, setUser } = useContext(UserContext); // Use user context
    const navigate = useNavigate();

    useEffect(() => {
        const handlePaymentSuccess = async () => {
            try {
                // Retrieve order number from local storage
                const orderNumber = localStorage.getItem('orderNumber');
            
                // Retrieve temporary user from local storage if user is not signed in
                const tempUser = !user ? JSON.parse(localStorage.getItem('tempUser')) : null;
            
                // Prepare order details
                const orderDetails = prepareOrderDetails(user || tempUser, cartItems, orderNumber);
                console.log("API OBJ: " + JSON.stringify(orderDetails, null, 2));
            
                // Send order details to database
                const response = await sendOrderDetailsToDB(orderDetails);
                console.log(response);
                if (!response.success) {
                    console.error('Failed to send order details to database:', response.message);
                }
                
                clearCart();
        
                // Redirect to the home page after a delay
                const timer = setTimeout(() => {
                    navigate('/');
                }, 3000);
            
                // Cleanup the timer on component unmount
                return () => clearTimeout(timer);
                
            } catch (error) {
                console.error('Error during payment success handling:', error);
            }
        };

        handlePaymentSuccess();
    }, []); // Empty dependency array to ensure it only runs once

    return (
        <div className="payment-successful-container">
        <h1>Payment Successful</h1>
        <p>Thank you for your purchase! Your payment has been successfully processed.</p>
        <Link to="/" className="btn btn-primary">Return to Home</Link>
        </div>
    );
};

export default PaymentSuccessfulPage;