import axios from 'axios';
// import Cookies from 'js-cookie';

export const getApiBaseUrl = () => {
  if (window.location.origin.includes('localhost')) {
    // return 'http://localhost:8181';
    return 'http://213.207.181.30:9080';
  } else {
    return window.location.origin;
  }
};

const returnUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8181/Payment-successful' // Localhost URL for development
  : 'https://daka.com.cy/Payment-successful'; // Production URL

// Helper method to encode API Query String
export const objectToQueryString = (obj) => {
  const queryString = Object.keys(obj).map(key => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
  }).join('&');
  return queryString;
};

// Centralized API Request Function with better error handling
const fetchFromApi = async (queryParams, endpoint = 'TableGetView') => {
  const baseUrl = getApiBaseUrl();
  const url = new URL(`$${endpoint}`, baseUrl);

  Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
  console.log("API: " + url.toString());
  
  try {
    const response = await axios.get(url.toString());
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};

// Universal POST API Request Function
export const postToApi = async (endpoint, body) => {
  const baseUrl = getApiBaseUrl();
  const url = `${baseUrl}/${endpoint}`;

  try {
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    console.error(`Error posting data to ${endpoint}:`, error);
    throw error;
  }
};

// Helper function to fetch images
const fetchImageUrl = (record, file, system = 'pos', additionalQueryParams = {}) => {
  const queryParams = {
    system,
    file,
    record,
    ...additionalQueryParams, // Merge additional query params
  };

  return `${getApiBaseUrl()}/$TableImage?${objectToQueryString(queryParams)}`;
};

// Check if User Exists
const checkUserExists = async (email) => {
  const findUserBody = {
    system: 'accounting',
    index: 'email',
    file: 'address',
    find: true,
    fields: [
      {
        id: 'email',
        value: email,
      },
    ],
  };

  return await postToApi('$TableRead', findUserBody);
};

// Add to Favorites
export const addToFavorites = async (userId, itemId) => {
  const requestBody = {
    system: 'pos',
    file: 'peopleitem',
    fields: [
      {
        id: 'people',
        value: userId,
      },
      {
        id: 'item',
        value: itemId,
      },
    ],
  };

  try {
    const response = await postToApi('$TableInsert', requestBody);
    return response;
  } catch (error) {
    console.error('Error adding to favorites:', error);
    return null;
  }
};

// Fetch Favorites
export const fetchFavorites = async (userId) => {
  const queryParams = {
    system: 'pos',
    file: 'peopleitem',
    driving: 'people',
    record: userId,
    __internal: 'true',
  };

  try {
    const data = await fetchFromApi(queryParams, 'TableGetView');
    const itemIds = data.map((favorite) => ({
      itemId: favorite.$item,
      position: favorite.$$position,
    }));

    const itemsWithDetails = await Promise.all(
      itemIds.map(async ({ itemId, position }) => {
        const itemDetails = await fetchFromApi({ system: 'pos', file: 'item', record: itemId }, 'TableRead');

        const sizesAPI = `${getApiBaseUrl()}/$TableGetView?system=pos&file=itemcolor&driving=item&record=${itemId}&__internal=true`;
        const sizesResponse = await axios.get(sizesAPI);
        const sizesData = sizesResponse.data;

        if (!Array.isArray(sizesData)) {
          throw new Error(`Expected an array but received: ${JSON.stringify(sizesData)}`);
        }

        const sizes = sizesData
          .map((sizeItem) => sizeItem.size)
          .filter((size) => size !== undefined)
          .map((size) => size.split('\t')[1]);

        const colors = sizesData
          .filter((colorItem) => colorItem.color)
          .map((colorItem) => colorItem.color);

        // Fetch attachment names
        const attachmentsAPI = `${getApiBaseUrl()}/$TableAttachments?system=pos&file=item&record=${itemId}&__internal=true`;
        const attachmentsResponse = await axios.get(attachmentsAPI);
        const attachmentNames = attachmentsResponse.data;

        if (!Array.isArray(attachmentNames)) {
          throw new Error(`Expected an array but received: ${JSON.stringify(attachmentNames)}`);
        }

        const filteredAttachmentNames = attachmentNames
          .map((attachment) => attachment.file)
          .filter((file) => file !== undefined);

        // Match colors and find image titles
        const colorImages = colors.map((color) => {
          const colorWithDashes = color.replace(/\s+/g, '-').toUpperCase();
          const matchingImages = filteredAttachmentNames.filter((file) => file.includes(colorWithDashes));

          // Call tableimage API for each matching image title
          const imageUrls = matchingImages.map((imageName) => {
            return `${getApiBaseUrl()}/$TableImage?system=pos&file=item&attach=${imageName}&record=${itemId}`;
          });

          return {
            name: color,
            images: imageUrls,
          };
        });

        // Construct the actual URL of the item
        const itemUrl = `/${itemDetails.category}/${itemDetails.subcategory}/${itemDetails.code}`;

        return {
          id: itemDetails.$$position,
          name: itemDetails.name,
          code: itemDetails.code,
          price: itemDetails.$price,
          description: itemDetails.$$notes || '',
          imageUrl: fetchImageUrl(itemDetails.$$position, 'item'),
          colors: colorImages,
          link: itemUrl, // Use the actual URL of the item
          sizes,
          position, // Save the $$position field
        };
      })
    );

    return itemsWithDetails;
  } catch (err) {
    console.error('Error fetching favorite items:', err);
    throw err;
  }
};

//remove from favorites
export const removeFromFavorites = async (itemId) => {
  const queryParams = {
    system: 'pos',
    file: 'peopleitem',
    record: itemId,
  };

  try {
    const response = await fetchFromApi(queryParams, 'TableErase');
    console.log('Item removed from favorites:', response);
    return response;
  } catch (error) {
    console.error('Error removing item from favorites:', error);
    return null;
  }
};

// Register New User
const registerUser = async (name, phone, password, group) => {
  const registerUserBody = {
    system: 'accounting',
    file: 'people',
    fields: [
      {
        id: 'name',
        value: name,
      },
      {
        id: 'phone',
        value: phone,
      },
      {
        id: '@bookmark',
        value: password
      },
      {
        id: 'group',
        value: group,
      }

    ],
  };

  return await postToApi('$TableInsert', registerUserBody);
};

// Insert Address for New User
const insertAddress = async (peopleId, email, phone) => {
  const insertAddressBody = {
    system: 'accounting',
    file: 'address',
    fields: [
      {
        id: 'people',
        value: peopleId,
      },
      {
        id: 'email',
        value: email,
      },
      {
        id: 'mobile',
        value: phone
      }
    ],
  };

  return await postToApi('$TableInsert', insertAddressBody);
};

// Fetch User Orders
export const fetchUserOrders = async (userId) => {
  const queryParams = {
    system: 'pos',
    file: 'trans',
    driving: 'people',
    record: userId,
    report: 'default',
    set_report: 'true',
    report_fields: '_total',
    fields: 'date',
  };

  try {
    const response = await fetchFromApi(queryParams, 'TableGetView');
    const orders = response.map((order) => ({
      id: order.$$position,
      date: order['@create_stamp'],
      total: order.$_total,
    }));
    return orders;
  } catch (error) {
    console.error('Error fetching user orders:', error);
    return [];
  }
};

// Fetch Order Details
export const fetchOrderDetails = async (transactionId) => {
  const queryParams = {
    system: 'pos',
    file: 'transdet',
    driving: 'trans',
    record: transactionId,
    __internal: 'true',
  };

  try {
    const response = await fetchFromApi(queryParams, 'TableGetView');
    const orderDetails = response.map((detail) => ({
      notes: detail.$$notes,
      price: detail.$price,
      quantity: detail.$quantity*(-1),
      total: Math.round(detail.$total * -1),
    }));
    return orderDetails;
  } catch (error) {
    console.error('Error fetching order details:', error);
    return [];
  }
};

export const registerNewUser = async (name, surname, email, password, phone, group) => {
  try {
    // Step 1: Check if user already exists
    const userExists = await checkUserExists(email);
    if (userExists && userExists.length > 0) {
      console.log('User already exists');
      return null;
    }

    // Step 2: Register new user
    const fullName = `${name} ${surname}`;
    const newUserResponse = await registerUser(fullName, phone, password, group);
    const newUserId = newUserResponse; // Assuming the response contains the new user ID
    // console.log("ID: " + newUserId);

    // Step 3: Insert address for the new user
    const addressResponse = await insertAddress(newUserId, email);
    // console.log("New User: " + addressResponse);
    if (!addressResponse || addressResponse === null) {
      console.error('Failed to insert address');
      return null;
    }

    // Construct the newUser object with the specified details
    const newUser = {
      name,
      surname,
      password,
      phone,
      email,
      email_id: addressResponse,
      initials: `${name.charAt(0)}${surname.charAt(0)}`,
      id: newUserId, // id returned from register API
      orders: [], // Initialize with an empty orders array
    };

    console.log('User registered successfully with ID:', newUserId);
    return newUser;
  } catch (error) {
    console.error('Error during user registration process:', error);
    return null;
  }
};

// Sign In User Process
export const signInUser = async (email, password) => {
  try {
    // Step 1: Check if user exists
    const userExistsResponse = await checkUserExists(email);
    // console.log('User Exists Response:', userExistsResponse);
    if (!userExistsResponse || Object.keys(userExistsResponse).length === 0) {
      console.log('User does not exist');
      return null;
    }

    // Extract the $people value from the response
    const peopleId = userExistsResponse.$people;
    const emailId = userExistsResponse.$$position;
    // console.log('People ID:', peopleId);
    // console.log('Email ID:', emailId);

    // Step 2: Fetch user details
    const userDetailsResponse = await fetchFromApi({
      system: 'accounting',
      file: 'people',
      record: peopleId,
    }, 'TableRead');

    const userDetails = userDetailsResponse;
    // console.log('User Details:', userDetails);

    // Step 3: Verify password
    if (userDetails['@bookmark'] !== password) {
      console.log('Invalid password');
      return null;
    }

      // Step 4: Fetch user orders
      const orders = await fetchUserOrders(peopleId);
      console.log(userDetails.name);
    // Construct the user object with the specified details
    const user = {
      name: userDetails.name,
      group: userDetails.group,
      id: userDetails.$$position,
      email,
      email_id: emailId, // Save the $$position as email_id
      initials: `${userDetails.name.charAt(0)}${userDetails.name.split(' ')[1].charAt(0)}`,
      addr1: userExistsResponse.addr1 || '',
      zip: userExistsResponse.zip || '',
      phone: userExistsResponse.mobile || '',
      orders, // Save the fetched orders
    };

    // console.log("initials: " + user.initials);
    // console.log('User signed in successfully with ID:', user.id);
    return user;
  } catch (error) {
    console.error('Error during user sign-in process:', error);
    return null;
  }
};

// Reset Password Process
export const resetPassword = async (email, oldPassword, newPassword) => {
  try {
    // Step 1: Check if user exists
    const userExistsResponse = await checkUserExists(email);
    console.log('User Exists Response:', userExistsResponse);
    if (!userExistsResponse || Object.keys(userExistsResponse).length === 0) {
      console.log('User does not exist');
      return null;
    }

    // Extract the $people value from the response
    const peopleId = userExistsResponse.$people;
    console.log('People ID:', peopleId);

    // Step 2: Fetch user details
    const userDetailsResponse = await fetchFromApi({
      system: 'accounting',
      file: 'people',
      record: peopleId,
    }, 'TableRead');

    const userDetails = userDetailsResponse;
    // console.log('User Details:', userDetails);

    // Step 3: Verify old password
    if (userDetails['@bookmark'] !== oldPassword) {
      console.log('Invalid old password');
      return null;
    }

    // Step 4: Update password
    const updatePasswordBody = {
      system: 'accounting',
      file: 'people',
      record: peopleId,
      contents: [
        {
          id: '@bookmark',
          value: newPassword,
        },
      ],
    };

    const updateResponse = await postToApi('$TableModify', updatePasswordBody);
    // console.log('Password updated successfully:', updateResponse);
    return updateResponse;
  } catch (error) {
    console.error('Error during password reset process:', error);
    return null;
  }
};

// Delete Account Process
export const deleteAccount = async (userId, emailId) => {
  try {
    // Step 1: Modify the people table to change all account data to XXXXXX
    const modifyPeopleBody = {
      system: 'accounting',
      file: 'people',
      record: userId,
      contents: [
        { id: 'name', value: 'XXXXXX' },
        { id: 'phone', value: 'XXXXXX' },
        { id: '@bookmark', value: 'XXXXXX' },
      ],
    };
    await postToApi('$TableModify', modifyPeopleBody);

    // Step 2: Modify the address table to change all account data to XXXXXX
    const modifyAddressBody = {
      system: 'accounting',
      file: 'address',
      record: emailId,
      contents: [
        { id: 'email', value: 'XXXXXX' },
      ],
    };
    await postToApi('$TableModify', modifyAddressBody);

    // Step 3: Soft delete the account
    const softDeleteUrl = `http://213.207.181.30:9080/$TableSoftErase?system=accounting&file=people&record=${userId}&soft=true`;
    await axios.get(softDeleteUrl);

    console.log('Account deleted successfully.');
    return true;
  } catch (error) {
    console.error('Error during account deletion process:', error);
    return false;
  }
};


// Fetch Categories Function
export const fetchCategories = async () => {
  const queryParams = {
    system: 'pos',
    file: 'type',
    report: 'web_type',
  };

  try {
    const data = await fetchFromApi(queryParams);

    const categoriesWithImages = await Promise.all(
      data.map(async (category) => ({
        id: category.$$position,
        name: category.name,
        imageUrl: fetchImageUrl(category.$$position, 'type'),
        link: `/${category['@bookmark']}`,
        comingSoon: category.name !== 'SCHOOL UNIFORMS',
      }))
    );

    return categoriesWithImages.sort((a, b) => a.id - b.id);
  } catch (err) {
    console.error('Error fetching categories:', err);
    throw err;
  }
};

// Fetch Subcategories Function
export const fetchSubCategories = async (recordpos) => {
  const queryParams = {
    system: 'pos',
    file: 'category',
    driving: 'type',
    record: recordpos,
    __internal: 'true',
  };

  try {
    const data = await fetchFromApi(queryParams);

    return data.map((category) => ({
      id: category.$$position,
      name: category.name,
      imageUrl: fetchImageUrl(category.$$position, 'category', 'pos'),
      link: `/${category['@bookmark']}`,
    }));
  } catch (err) {
    console.error('Error fetching subcategories:', err);
    throw err;
  }
};

// Fetch Item Categories
export const fetchItemCategories = async (recordpos) => {
  const queryParams = {
    system: 'pos',
    file: 'category',
    driving: 'category',
    record: recordpos,
    __internal: 'true',
  };

  try {
    const data = await fetchFromApi(queryParams);

    return data.map((category) => ({
      id: category.$$position,
      name: category.name.replace(/\s*(girls|boys)$/i, ''), // Remove 'girls' or 'boys'
      imageUrl: fetchImageUrl(category.$$position, 'category'),
      link: `/${category['@bookmark']}`,
    }));
  } catch (err) {
    console.error('Error fetching item categories:', err);
    throw err;
  }
};


// Fetch Items per Category
export const fetchItemsPerCategory = async (recordpos) => {
  const queryParams = {
    system: 'pos',
    file: 'item',
    driving: 'category',
    record: recordpos,
    __internal: 'true',
  };

  try {
    const data = await fetchFromApi(queryParams);

    const itemsWithDetails = await Promise.all(
      data.map(async (item) => {
        const sizesAPI = `${getApiBaseUrl()}/$TableGetView?system=pos&file=itemcolor&driving=item&record=${item.$$position}&__internal=true`;
        const sizesResponse = await axios.get(sizesAPI);
        // console.log(sizesAPI);
        // console.log(sizesResponse);
        const sizes = sizesResponse.data
        ? sizesResponse.data
            .map((sizeItem) => sizeItem.size)
            .filter((size) => size !== undefined)
            .map((size) => size.split('\t')[1])
        : [];
        const colors = sizesResponse.data
          .filter((colorItem) => colorItem.color)
          .map((colorItem) => colorItem.color);
        // console.log(colors);

        // Step 1: Fetch attachment names
        const attachmentsAPI = `${getApiBaseUrl()}/$TableAttachments?system=pos&file=item&record=${item.$$position}&__internal=true`;
        const attachmentsResponse = await axios.get(attachmentsAPI);
        const attachmentNames = attachmentsResponse.data
                                .map((attachment) => attachment.file)
                                .filter((file) => file !== undefined);
                                
        console.log("Attachment Names" + attachmentNames);

        // Step 2: Match colors and find image titles
        const colorImages = colors.map((color) => {
          const colorWithDashes = color.replace(/\s+/g, '-').toUpperCase();
          console.log(colorWithDashes);
          const matchingImages = attachmentNames.filter((file) => file.includes(colorWithDashes));
          console.log(matchingImages);

          // Step 3: Call tableimage API for each matching image title
          const imageUrls = matchingImages.map((imageName) => {
            return `${getApiBaseUrl()}/$TableImage?system=pos&file=item&attach=${imageName}&record=${item.$$position}`;
          });

          return {
            name: color,
            images: imageUrls,
          };
        });

        console.log('Before colorImages log');
        console.log(colorImages);
        console.log('After colorImages log');

        return {
          id: item.$$position,
          name: item.name,
          code: item.code,
          price: item.$price,
          description: item.$$notes || '',
          imageUrl: fetchImageUrl(item.$$position, 'item'),
          colors: colorImages,
          link: `/${item['@bookmark']}`,
          sizes,
        };
      })
    );
    return itemsWithDetails;
  } catch (err) {
    console.error('Error fetching items:', err);
    throw err;
  }
};


// Fetch All Items
export const fetchAllItems = async () => {
  const queryParams = {
    system: 'pos',
    file: 'item',
    driving: 'category',
    record: 19,
    __internal: 'true',
  };

  try {
    const data = await fetchFromApi(queryParams);

    const itemsWithDetails = await Promise.all(
      data.map(async (item) => {
        const sizesAPI = `${getApiBaseUrl()}/$TableGetView?system=pos&file=itemcolor&driving=item&record=${item.$$position}&__internal=true`;
        const sizesResponse = await axios.get(sizesAPI);
        console.log(sizesAPI);
        console.log(sizesResponse);

        let sizes = [];
        let colors = [];

        // Check if sizesResponse.data is an array before filtering and mapping
        if (Array.isArray(sizesResponse.data)) {
          sizes = sizesResponse.data
            .filter((sizeItem) => sizeItem.size)
            .map((sizeItem) => sizeItem.size.split('\t')[1]);

          colors = sizesResponse.data
            .filter((colorItem) => colorItem.color)
            .map((colorItem) => colorItem.color);
        } else if (sizesResponse.data && typeof sizesResponse.data === 'object') {
          // Handle case where the response is an object, convert it to an array if needed
          sizes = [sizesResponse.data].filter((sizeItem) => sizeItem.size).map((sizeItem) => sizeItem.size);
          colors = [sizesResponse.data].filter((colorItem) => colorItem.color).map((colorItem) => colorItem.color);
        } else {
          console.error('sizesResponse.data is not an array or object:', sizesResponse.data);
          // console.log('Type of sizesResponse.data:', typeof sizesResponse.data);
        }

        // console.log(colors);

        // Step 1: Fetch attachment names
        const attachmentsAPI = `${getApiBaseUrl()}/$TableAttachments?system=pos&file=item&record=${item.$$position}&__internal=true`;
        const attachmentsResponse = await axios.get(attachmentsAPI);
        const attachmentNames = Array.isArray(attachmentsResponse.data)
          ? attachmentsResponse.data.map((attachment) => attachment.file).filter((file) => file !== undefined)
          : [];

        // console.log("Attachment Names", attachmentNames);

        // Step 2: Match colors and find image titles
        const colorImages = colors.map((color) => {
          const colorWithDashes = color.replace(/\s+/g, '-').toUpperCase();
          // console.log(colorWithDashes);
          const matchingImages = attachmentNames.filter((file) => file.includes(colorWithDashes));
          // console.log(matchingImages);

          // Step 3: Call tableimage API for each matching image title
          const imageUrls = matchingImages.map((imageName) => {
            return `${getApiBaseUrl()}/$TableImage?system=pos&file=item&attach=${imageName}&record=${item.$$position}`;
          });

          return {
            name: color,
            images: imageUrls,
          };
        });

        console.log('Before colorImages log');
        console.log(colorImages);
        console.log('After colorImages log');

        return {
          id: item.$$position,
          name: item.name,
          code: item.code,
          price: item.$price,
          description: item.$$notes || '',
          imageUrl: fetchImageUrl(item.$$position, 'item'),
          colors: colorImages,
          link: `/${item['@bookmark']}`,
          sizes,
        };
      })
    );

    return itemsWithDetails;
  } catch (err) {
    console.error('Error fetching items:', err);
    throw err;
  }
};


// Fetch Product by ID
export const fetchProductById = async (id, useTableRead = true) => {
  const endpoint = useTableRead ? 'TableRead' : 'TableGetView';
  const queryParams = {
    system: 'pos',
    file: 'item',
    record: id,
    __internal: 'true',
  };

  try {
    const product = await fetchFromApi(queryParams, endpoint);

    // Fetch sizes and colors for the product
    const sizesQueryParams = {
      system: 'pos',
      file: 'itemcolor',
      driving: 'item',
      record: id,
      __internal: 'true',
    };
    
    const sizesResponse = await fetchFromApi(sizesQueryParams, 'TableGetView');
    const sizes = sizesResponse
      ? sizesResponse.map((sizeItem) => sizeItem.size).filter((size) => size !== undefined)
      : [];

    const colors = sizesResponse
      .filter((colorItem) => colorItem.color)
      .map((colorItem) => colorItem.color);

    // Step 1: Fetch attachment names
    const attachmentsAPI = `${getApiBaseUrl()}/$TableAttachments?system=pos&file=item&record=${id}&__internal=true`;
    const attachmentsResponse = await axios.get(attachmentsAPI);
    const attachmentNames = attachmentsResponse.data
      .map((attachment) => attachment.name)
      .filter((name) => name !== undefined); // Ensure name is defined
    // console.log(attachmentNames);

    // Step 2: Match colors and find image titles
    const colorImages = colors.map((color) => {
      const colorWithDashes = color.replace(/\s+/g, '-').toUpperCase();
      const matchingImages = attachmentNames.filter((name) => name.includes(colorWithDashes));
      // console.log(matchingImages);

      // Step 3: Call tableimage API for each matching image title
      const imageUrls = matchingImages.map((imageName) => {
        return `${getApiBaseUrl()}/$TableImage?system=pos&file=item&attach=${imageName}&record=${id}`;
      });

      return {
        name: color,
        images: imageUrls,
      };
    });

    // console.debug(colorImages);

    return {
      id: product.$$position,
      name: product.name,
      code: product.code,
      price: product.$price,
      description: product.$$notes || '',
      imageUrl: fetchImageUrl(product.$$position, 'item'),
      colors: colorImages,
      link: `/${product['@bookmark']}`,
      sizes,
    };
  } catch (err) {
    console.error('Error fetching product by ID:', err);
    throw err;
  }
};

// Fetch Carousel Images
export const fetchCarouselImgs = async (recordpos) => {
  const queryParams = {
    system: 'pos',
    file: 'carousel',
    driving: 'category', 
    record: recordpos,
    attachments: true,
    __internal: true,
  };

  try {
    console.log("GET CAROUSEL");
    const viewData = await fetchFromApi(queryParams);
    // console.log(viewData)
    
    // Extract $$attachments field from the response
    const attachments = viewData.map((item) => item.$$attachments.split(/\f/g));
    const carouselPos = viewData.map(item => item.$$position);
    
    const imageUrls = attachments.flatMap((attachment) =>
      attachment.map((singleFile) => {
        const imageQueryParams = {
          system: 'pos',
          file: 'carousel',
          attach: singleFile.trim(), // Trim any whitespace or newline characters
          record: carouselPos,
        };
        return fetchImageUrl(carouselPos, 'carousel', 'pos', imageQueryParams);
      })
    );

    return imageUrls;
  } catch (err) {
    console.error('Error fetching carousel images:', err);
    throw err;
  }
};


export const fetchCarouselImgsType = async (recordpos, additionalQueryParams = {}) => {
  const queryParams = {
    system: 'pos',
    file: 'carousel',
    record: recordpos,
    __internal: true,
  };

  const getAttachmentsAPI = `${getApiBaseUrl()}/$TableAttachments?${objectToQueryString(queryParams)}`;
  console.log(getAttachmentsAPI);

  try {
    // Call the TableAttachments API
    const { data: attachmentsData } = await axios.get(getAttachmentsAPI);
    console.log('Attachments API response data:', attachmentsData); // Log the data to debug

    if (!attachmentsData || attachmentsData.length === 0) {
      throw new Error("No attachments found for the given record position");
    }

    // Extract file names from the response
    const fileNames = attachmentsData.map(item => item.file);

    // Call the TableImage API for each file name
    const imageUrls = await Promise.all(fileNames.map(async (fileName) => {
      const imageQueryParams = {
        system: 'pos',
        file: 'carousel',
        attach: fileName,
        record: recordpos,
        ...additionalQueryParams, // Merge additional query params
      };

      return fetchImageUrl(recordpos, 'carousel', 'pos', imageQueryParams); // Use fetchImageUrl
    }));

    return imageUrls;
  } catch (err) {
    console.error("Error while fetching carousel images: ", err);
    throw err;
  }
};

// Register Order with Payment Provider
export const registerOrderWithPaymentProvider = async (amount, order_number, description) => {

  const queryParams = {
    amount: amount,
    order_number: order_number,
    description: description,
    return_url: returnUrl,
  };

  try {
    const response = await fetchFromApi(queryParams, 'PosJcc');
    console.log('API response:', response);

    if (response && response.formUrl) {
      return {
        formUrl: response.formUrl,
        orderId: response.orderId,
      };
    } else {
      throw new Error('Failed to register order with payment provider');
    }
  } catch (error) {
    console.error('Error registering order with payment provider:', error);
    throw error;
  }
};

// export const registerOrderWithPaymentProvider = async (amount, orderNumber, description) => {
//   const paymentGatewayUrl = '/payment/rest/register.do';
//   const returnUrl_ = returnUrl;
//   // console.log("Amount 2: ", amount);
//   const requestBody = new URLSearchParams();
//   requestBody.append('amount', amount);
//   requestBody.append('currency', '978'); // Assuming EUR currency
//   requestBody.append('userName', 'Daka_Eshop-api');
//   requestBody.append('password', 'ydxwv-D1');
//   requestBody.append('returnUrl', returnUrl_);
//   requestBody.append('description', description);
//   requestBody.append('language', 'en');
//   requestBody.append('orderNumber', orderNumber);

//   try {
//     const response = await axios.post(paymentGatewayUrl, requestBody, {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     });

//     console.log('API response:', response.data);

//     if (response.data && response.data.formUrl) {
//       return {
//         formUrl: response.data.formUrl,
//         orderId: response.data.orderId,
//       };
//     } else {
//       throw new Error('Failed to register order with payment provider');
//     }
//   } catch (error) {
//     console.error('Error registering order with payment provider:', error);
//     throw error;
//   }
// };

// Send Order Details to Database
export const sendOrderDetailsToDB = async (orderDetails) => {
  try {
    const response = await postToApi('$PosProcessWeb', orderDetails);
    if (response && response.error) {
      console.error('Error in response from database:', response.data.error);
      return { success: false, message: response.data.error };
    }
    console.log('Order details sent to database successfully:', response);
    return { success: true, response };
  } catch (error) {
    console.error('Error sending order details to database:', error);
    // Handle the error gracefully
    return { success: false, message: 'Failed to send order details to database.' };
  }
};

// Prepare Order Details
export const prepareOrderDetails = (user, cartItems, orderId) => {
  const orderDate = new Date();
  const orderDateString = orderDate.toLocaleDateString('en-GB').replace(/\//g, '\\/'); 
  const orderTimeString = orderDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

  const customerdetails = user.guest
    ? {
        name: user.firstName,
        surname: user.lastName,
        phone: user.phone,
        email: user.email,
        addr1: user.addr1,
        addr2: user.addr2 || '',
        postal: user.zip,
        city: user.city,
        state: user.state,
        country: user.country,
        guest: true,
      }
    : { email: user.email,
        phone: user.phone,
        addr1: user.addr1,
        postal: user.zip,
        city: user.city,
     };

  return {
    documentid: 18,
    orderid: orderId, // GUID
    location: '1',
    orderdate: orderDateString,
    ordertime: orderTimeString,
    currency: 'EUR',
    paymentmethod: 'JCC',
    note: '',
    customerdetails,
    orderitems: cartItems.map((item) => ({
      model: item.code,
      color: item.color,
      size: item.size,
      qty: item.quantity,
      total: item.price,
    })),
  };
};

// Update Customer Address
export const updateCustomerAddress = async (recordId, add1, zip, mobile) => {
  const requestBody = {
    system: 'accounting',
    file: 'address',
    record: recordId,
    contents: [
      {
        id: 'add1',
        value: add1,
      },
      {
        id: 'zip',
        value: zip,
      },
      {
        id: "mobile",
        value: mobile
      }
    ],
  };

  try {
    const response = await postToApi('$TableModify', requestBody);
  
    return response;
  } catch (error) {
    console.error('Error updating customer address:', error);
    return null;
  }
};


export const validateVoucherCode = async (voucherCode) => {
  const queryParams = {
    find: voucherCode,
    simple: 'true',
    location: "01",
  };

  try {
    const response = await fetchFromApi(queryParams, 'PosFindVoucher');
    console.log(response);
    if (response) {
      // const voucher = response[0];
      return response.$collected;
    } else {
      console.error('Invalid voucher code or no data returned');
      return null;
    }
  } catch (error) {
    console.error('Error validating voucher code:', error);
    return null;
  }
};


export const getStockForItem = async (itemCode) => {
  const queryParams = {
    code: itemCode,
    __internal: 'true',
  };

  try {
    const response = await fetchFromApi(queryParams, 'PosGetStock');
    return response;
  } catch (error) {
    console.error('Error fetching stock for item:', error);
    return null;
  }
};
